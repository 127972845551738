import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import StationsContainer from './containers/StationsContainer/StationsContainer';
import { useStore } from './store/store';
import ModalContainer from './containers/ModalContainer/ModalContainer';
import LoadingComponent from './components/Loadings/LoadingComponent/LoadingComponent';
import RememberPassword from './components/RememberPassword/RememberPassword';
import LoginForm from './components/Login/LoginForm';
import AuthorizeToken from './components/AuthorizeToken/AuthorizeToken';
import PrivateRoute from './router/PrivateRoute';
import NotFound from './components/errors/NotFound/NotFound';
import { PathRoute } from './constants/pathRoute/Route';
import VerifyNewPassword from './components/VerifyNewPassword/VerifyNewPassword';
import CircleKMainPage from './containers/CircleKMainPage/CircleKMainPage';
import DashboardRoute from './components/DashboardRoute/DashboardRoute';
import Approvals from './containers/Approvals/Approvals';
import UserProfile from './containers/UserProfile/UserProfile';
import Contact from './containers/Contact/Contact';
import EmailConfirmation from './containers/EmailConfirmation/EmailConfirmation';
import EmailConfirmationSuccess from './containers/EmailConfirmationSuccess/EmailConfirmationSuccess';
import MyFiles from './containers/MyFiles/MyFiles';
import Loan from './containers/Loan/Loan';
import News from './containers/News/News';
import ConfirmLoan from './components/Loan/ConfirmLoan/ConfirmLoan';
import NotificationContainer from './containers/NotificationContainer/NotificationContainer';
import NewBenefits from './containers/NewBenefits/NewBenefits';
import WayCash from './components/WayCash/WayCash';
import DocumentsLoan from './components/Loan/DocumentsLoan/DocumentsLoan';
import ProgressLoan from './components/Loan/ProgressLoan/ProgressLoan';
import CustomerAddressContractFormLoan from './components/Loan/CustomerAddressContractFormLoan/CustomerAddressContractFormLoan';
import CustomerDataContractFormLoan from './components/Loan/CustomerDataContractFormLoan/CustomerDataContractFormLoan';
import CustomerEmploymentContractFormLoan from './components/Loan/CustomerEmploymentContractFormLoan/CustomerEmploymentContractFormLoan';
import VerifyLoan from './components/Loan/VerifyLoan/VerifyLoan';
import Users from './components/Users/Users';
import RegisterCustomerForm from './containers/RegisterCustomerForm/RegisterCustomerForm';
import EditCustomerForm from './containers/EditCustomerForm/EditCustomerForm';
import LoanApplicationHistory from './containers/Loan/LoanApplicationHistory';
import LoanUserMonths from './components/Loan/LoanBenefitManagement/LoanUserMonths';
import DiscountCode from './containers/DiscountCode/DiscountCode';
import CustomerFileContractFormDiscountCode from './containers/DiscountCode/CustomerFileContractFormDiscountCode/CustomerFileContractFormDiscountCode';
import CustomerContractFormDiscountCode from './containers/DiscountCode/CustomerContractFormDiscountCode/CustomerContractFormDiscountCode';
import SignContractFormDiscountCode from './containers/DiscountCode/SignContractFormDiscountCode/SignContractFormDiscountCode';
import ConfirmDiscountCodeOrder from './components/DiscountCode/ConfirmDiscountCodeOrder/ConfirmDiscountCodeOrder';
import DiscountCodeOrderSuccess from './components/DiscountCode/DiscountCodeOrderSuccess/DiscountCodeOrderSuccess';
import StartThirteen from './containers/Thirteen/StartThirteen/StartThirteen';
import DiscountCodeApprovalsForm from './containers/DiscountCode/DiscountCodeApprovalsForm/DiscountCodeApprovalsForm';
import DiscountCodeTechnicalBreak from './components/DiscountCode/DiscountCodeTechnicalBreak/DiscountCodeTechnicalBreak';
import UserInstitutionalDiscountCodes from './components/DiscountCode/UserInstitutionalDiscountCodes/UserInstitutionalDiscountCodes';
import PropertyInsurancePage from './containers/PropertyInsurance/PropertyInsurancePage';
import PropertyInsuranceExternalLayout from './containers/PropertyInsuranceExternal/PropertyInsuranceExternalLayout';
import UserBirthdayCodes from './containers/BirthdayCodes/UserBirthdayCodes/UserBirthdayCodes';
import BirthdayCodesSpecialCampaign from './containers/BirthdayCodes/BirthdayCodesSpecialCampaign/BirthdayCodesSpecialCampaign';
import NauClubGeneralInformations from './containers/NauClubGeneralInformations/NauClubGeneralInformations';
import AdminRoute from './router/AdminRoute';
import { SWRConfig } from 'swr';
import { requests } from './api/agent';
import WalletNau from './containers/WalletNau/WalletNau';
import WalletNauApprovalsForm from './containers/WalletNau/WalletNauApprovalsForm/WalletNauApprovalsForm';
import UserDataContractFormWalletNau from './containers/WalletNau/UserDataContractFormWalletNau/UserDataContractFormWalletNau';
import UserFileContractFormWalletNau from './containers/WalletNau/UserFileContractFormWalletNau/UserFileContractFormWalletNau';
import CashOnTheWay from './components/WalletNau/CashOnTheWay/CashOnTheWay';
import ConfirmWalletPayout from './components/WalletNau/ConfirmWalletPayout/ConfirmWalletPayout';
import Payments from './containers/Payments/Payments';
import StartDiscountCodeContainer from './containers/DiscountCode/StartDiscountCode/StartDiscountCodeContainer';
import StartLoanNau from './containers/LoanNau/StartLoanNau/StartLoanNau';
import CashbackUserRegister from './containers/Cashback/CashbackUserRegister/CashbackUserRegister';
import DeleteAccount from './containers/DeleteAccount/DeleteAccount';
import FuelCardOperatorChangeNauProfit from './containers/FuelCardOperatorChangeNauProfit/FuelCardOperatorChangeNauProfit';
import ThankYouPage from './containers/Payments/ThankYouPage/ThankYouPage';
import CinemaVoucherOrderSummary from './components/CinemaVoucher/CinemaVoucherOrderSummary/CinemaVoucherOrderSummary';
import CinemaVoucherOrderClientData from './components/CinemaVoucher/CinemaVoucherOrderClientData/CinemaVoucherOrderClientData';
import { ACTIVATION_GUID, DISABLE_APPROVALS, INSTITUTION_TOKEN, PROMO_CODE_KEY } from './constants/sessionStorageKeys';
import BackButtonProvider from './providers/BackButtonProvider';
import StartCarInsurance from './containers/CarInsurance/StartCarInsurance/StartCarInsurance';
import TripInsurancePage from './containers/TripInsurance/TripInsurancePage';
import { PhoneSubscriptionPage } from './containers/NauMobile/PhoneSubscriptionPage/PhoneSubscriptionPage';
import Register from './components/UserRegister/UserRegister';
import UserRegister from './components/UserRegister/UserRegister';
import CashbackLayout from './containers/Cashback/CashbackPage/CashbackLayout';
import CinemaVoucherLayout from './containers/CinemaVoucher/CinemaVoucherLayout';
import BirthdayCodeCampaignsLayout from './containers/BirthdayCodes/BirthdayCodeCampaigns/BirthdayCodeCampaignsLayout';
import { history } from './index';
import { CinemaVoucherPromoDirectorOrderSuccess } from './containers/CinemaVoucher/CinemaVoucherPromoDirectorOrderSuccess/CinemaVoucherPromoDirectorOrderSuccess';
import { DirectorHasCollectedCinemaVoucher } from './containers/CinemaVoucher/DirectorHasCollectedCinemaVoucher';
import { DirectorBadDataCinemaVoucher } from './containers/CinemaVoucher/DirectorBadDataCinemaVoucher';
import CinemaVoucherPromoDirectorLayout from './containers/CinemaVoucher/CinemaVoucherPromoDirectorLayout';
import KlThankYouPage from './containers/Kl/ThankYouPage/KlThankYouPage';
import KlAnonymousLayout from './containers/Kl/KlAnonymousLayout';
import SportCardLayout from './containers/SportCard/SportCardLayout';
import { CinemaVoucherPromoLinkExpiredLayout } from './containers/CinemaVoucher/CinemaVoucherPromoLinkExpiredLayout';
import { CinemaVoucherPromoLinkExpiredPage } from './containers/CinemaVoucher/CinemaVoucherPromoLinkExpiredPage';
import { PhoneSubscriptionLayout } from './containers/PhoneSubscription/PhoneSubscriptionLayout';
import { CinemaVoucherPromoNauOrderSuccess } from './containers/CinemaVoucher/CinemaVoucherPromoNauOrderSuccess/CinemaVoucherPromoNauOrderSuccess';

function App() {
  const { commonStore, userStore, menuStore } = useStore();

  useEffect(() => {
    loadAllValues().finally(() => commonStore.setAppLoaded(true));
  }, [commonStore, userStore]);

  const loadAllValues = async () => {
    const updateRequired = await userStore.checkUpdateRequired();

    if (updateRequired) {
      userStore.logout();
      return;
    }

    menuStore.setClosedMainCustomer();
    menuStore.setClosedProfile();
    await userStore.setIsDemoUser();

    if (commonStore.token) {
      await userStore.hasRequiredApprovals().then(() => {
        // userStore.setIsLoggedToInst();
      });
    }
  };

  if (!commonStore.appLoaded) {
    return <LoadingComponent content='Ładowanie...' visible={!commonStore.appLoaded} />;
  }

  const swrFetcher = function <T>(url: string) {
    return requests.get<T>(url);
  };

  const isLoggedInToInst = userStore.isLoggedInToInst;

  return (
    <SWRConfig value={{ fetcher: swrFetcher, shouldRetryOnError: false }}>
      <ToastContainer hideProgressBar position='bottom-right' />
      <ModalContainer />
      <BackButtonProvider />
      <Route
        render={() => (
          <div>
            <Switch>
              <Route component={NotFound} path={PathRoute.SERVER_ERROR} />
              <Route component={LoginForm} path={PathRoute.LOGIN} />
              <Route component={EmailConfirmationSuccess} path={PathRoute.EMAIL_CONFIRMATION_SUCCESS} />
              <Route component={RememberPassword} path={PathRoute.REMEMBER_PASSWORD} />
              <Route component={Register} path={PathRoute.REGISTER} />
              <Route
                path={PathRoute.PROMO_TEACHER_REGISTER}
                render={() => {
                  sessionStorage.setItem(PROMO_CODE_KEY, 'nauczycielvip');
                  return <LoginForm />;
                }}
              />
              <Route
                path={`${PathRoute.PROMO_REGISTER_WITH_CODE}/:code?`}
                render={({ match }) => {
                  if (match.params.code) {
                    sessionStorage.setItem(PROMO_CODE_KEY, match.params.code);
                  }
                  return <LoginForm />;
                }}
              />
              <Route path={PathRoute.CASHBACK_USER_REGISTER} render={() => <CashbackUserRegister />} />
              <Route path={PathRoute.CASHBACK_USER_REGISTER_2} render={() => <CashbackUserRegister />} />
              <Route
                path={`${PathRoute.PROMO_REGISTER}/:token?`}
                render={({ match }) => {
                  if (match.params.token) {
                    sessionStorage.setItem(INSTITUTION_TOKEN, match.params.token);
                  }
                  return <UserRegister />;
                }}
              />
              <Route
                path={`${PathRoute.PROMO_REGISTER_OPZZ}`}
                render={() => {
                  return <Redirect to={`${PathRoute.PROMO_REGISTER}/0024138804`} />;
                }}
              />
              <Route
                path={PathRoute.CINEMA_VOUCHERS_PROMOTION_SCHOOL_DIRECTOR}
                render={() => {
                  return <CinemaVoucherPromoLinkExpiredPage />;
                }}
              />
              <Route
                path={PathRoute.CINEMA_VOUCHERS_PROMOTION_SCHOOL_DIRECTOR_TEACHERS_DAY}
                render={() => {
                  sessionStorage.setItem(PROMO_CODE_KEY, 'kina-dzien-nauczyciela');
                  sessionStorage.setItem(DISABLE_APPROVALS, 'true');
                  history.push(PathRoute.CINEMA_VOUCHER_DIRECTOR_PROMO);
                  return <LoginForm />;
                }}
              />
              <Route
                path={`${PathRoute.CASHBACK_NNW_2024_REFUND_PROMO}/:key`}
                render={({ match }) => {
                  sessionStorage.setItem(PROMO_CODE_KEY, 'nnw-2024-zwrot-skladki-bonus?key=' + match.params.key);
                  history.push(PathRoute.CASHBACK);
                  return <LoginForm />;
                }}
              />
              <Route
                path={PathRoute.CASHBACK_NNW_KIDS}
                render={() => {
                  sessionStorage.setItem(PROMO_CODE_KEY, 'zwrot-skladki-nnw-dzieci');
                  history.push(PathRoute.CASHBACK);
                  return <LoginForm />;
                }}
              />
              <Route
                path={`${PathRoute.CINEMA_VOUCHERS_PROMOTION_SCHOOL_STUFF}/:token?`}
                render={({ match }) => {
                  if (match.params.token) {
                    sessionStorage.setItem(PROMO_CODE_KEY, 'kina-pracownik');
                    sessionStorage.setItem(INSTITUTION_TOKEN, match.params.token);
                  }
                  return <UserRegister />;
                }}
              />
              <Route component={AuthorizeToken} path={PathRoute.AUTHORIZE_TOKEN} />
              <Route component={VerifyNewPassword} path={PathRoute.VERIFY_NEW_PASSWORD} />
              <AdminRoute component={Users} exact path={PathRoute.ADMIN_DASHBOARD} />
              <AdminRoute component={RegisterCustomerForm} exact path={PathRoute.ADD_USER} />
              <AdminRoute component={Users} exact path={PathRoute.USERS} />
              <AdminRoute component={EditCustomerForm} exact path={PathRoute.EDIT_USER + '/:id'} />
              <AdminRoute component={LoanUserMonths} exact path={PathRoute.USERS_LOANS} />
              <PrivateRoute
                component={DashboardRoute}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CORDOVA_MAIN_SCREEN}
              />
              <PrivateRoute
                component={DashboardRoute}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.PUSTY}
              />
              <PrivateRoute
                component={DashboardRoute}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.APP}
              />
              <PrivateRoute
                component={StationsContainer}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.STATION_NEAR_HOME}
              />
              <PrivateRoute
                component={CircleKMainPage}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.FUEL_CARDS}
              />
              <PrivateRoute
                component={FuelCardOperatorChangeNauProfit}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.FUEL_CARD_OPERATOR_CHANGE}
              />
              <PrivateRoute
                component={Approvals}
                exact
                hasApprovals={null}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.APPROVALS}
              />
              <PrivateRoute
                component={UserProfile}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.USER_PROFILE}
              />
              <Route component={Contact} exact path={PathRoute.CONTACT} />
              <Route component={KlAnonymousLayout} exact path={PathRoute.KL_ORDER_FORM} />
              <Route component={KlThankYouPage} exact path={PathRoute.KL_THANK_YOU_PAGE} />
              <Route component={ThankYouPage} exact path={PathRoute.PAYMENT_THANK_YOU_PAGE} />
              <Route component={DeleteAccount} exact path={PathRoute.DELETE_ACCOUNT} />
              <PrivateRoute
                component={News}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.NEWS}
              />
              <PrivateRoute
                component={EmailConfirmation}
                exact
                hasApprovals={null}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.VERIFY_EMAIL}
              />
              <PrivateRoute
                component={NotificationContainer}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.MY_NOTIFICATIONS}
              />
              <PrivateRoute
                component={NewBenefits}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.NEW_BENEFITS}
              />
              <PrivateRoute
                component={MyFiles}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.MY_FILES}
              />
              <PrivateRoute
                component={ConfirmLoan}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CONFIRM_LOAN + '/:id?'}
              />
              <PrivateRoute
                component={Loan}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.LOAN}
              />
              <PrivateRoute
                component={LoanApplicationHistory}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.LOAN_APPLICATION_HISTORY}
              />
              <PrivateRoute
                component={WayCash}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.WAY_CASH}
              />
              <PrivateRoute
                component={DocumentsLoan}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.DOCUMENTS_LOAN}
              />
              <PrivateRoute
                component={VerifyLoan}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.VERIFY_LOAN + '/:id?'}
              />
              <PrivateRoute
                component={Approvals}
                exact
                hasApprovals={null}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.APPROVALS_LOAN}
              />
              <PrivateRoute
                component={ProgressLoan}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.PROGRESS_LOAN}
              />
              <PrivateRoute
                component={CustomerDataContractFormLoan}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.USER_CONTRACT_LOAN + '/:id?'}
              />
              <PrivateRoute
                component={CustomerAddressContractFormLoan}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.ADDRESS_CONTRACT_LOAN + '/:id'}
              />
              <PrivateRoute
                component={CustomerEmploymentContractFormLoan}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.EMPLOYMENT_CONTRACT_LOAN + '/:id'}
              />
              {/* <PrivateRoute
                component={CustomerAdditionalContractFormLoan}
                path={PathRoute.ADDITIONAL_CONTRACT_LOAN + "/:id"}
                exact
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                hasApprovals={userStore.hasApprovals}
              /> */}
              <PrivateRoute
                component={NewBenefits}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.PULPIT}
              />
              <PrivateRoute
                component={DiscountCode}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.DISCOUNT_CODE}
              />
              <PrivateRoute
                component={UserInstitutionalDiscountCodes}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.INSTITUTIONAL_DISCOUNT_CODE}
              />
              <PrivateRoute
                component={CustomerContractFormDiscountCode}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.USER_CONTRACT_DISCOUNT_CODE}
              />
              <PrivateRoute
                component={StartDiscountCodeContainer}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.DISCOUNT_CODE_START}
              />
              <PrivateRoute
                component={CustomerFileContractFormDiscountCode}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.FILE_CONTRACT_DISCOUNT_CODE}
              />
              <PrivateRoute
                component={SignContractFormDiscountCode}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.SIGN_CONTRACT_DISCOUNT_CODE}
              />
              <PrivateRoute
                component={ConfirmDiscountCodeOrder}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CONFIRM_DISCOUNT_CODE_ORDER + '/:id'}
              />
              <PrivateRoute
                component={DiscountCodeOrderSuccess}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.DISCOUNT_CODE_ORDER_SUCCESS}
              />
              <PrivateRoute
                component={DiscountCodeApprovalsForm}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.DISCOUNT_CODE_APPROVALS}
              />
              <PrivateRoute
                component={DiscountCodeTechnicalBreak}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.DISCOUNT_CODE_TECHNICAL_BREAK}
              />
              <PrivateRoute
                component={StartThirteen}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.THIRTEEN_START}
              />
              <Route
                exact
                path={PathRoute.PROPERTY_INSURANCE_EXTERNAL}
                render={() => {
                  if (userStore.isLoggedIn) {
                    return <PropertyInsurancePage />;
                  } else {
                    return <PropertyInsuranceExternalLayout />;
                  }
                }}
              />
              <PrivateRoute
                component={StartCarInsurance}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CAR_INSURANCE}
              />
              <PrivateRoute
                component={PropertyInsurancePage}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.PROPERTY_INSURANCE}
              />
              <PrivateRoute
                component={TripInsurancePage}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.TRIP_INSURANCE}
              />
              <Route
                path={PathRoute.USER_ACTIVATION + '/:guid'}
                render={({ match }) => {
                  if (match.params.guid) {
                    sessionStorage.setItem(ACTIVATION_GUID, match.params.guid);
                  }
                  return <RememberPassword isUserActivation={true} />;
                }}
              />
              <PrivateRoute
                component={BirthdayCodeCampaignsLayout}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.BIRTHDAY_CODES}
              />
              <PrivateRoute
                component={() => <UserBirthdayCodes />}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.MY_BIRTHDAY_CODES}
              />
              <PrivateRoute
                component={() => <BirthdayCodesSpecialCampaign />}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.BIRTHDAY_CODE_FOR_FREE}
              />
              <PrivateRoute
                component={NauClubGeneralInformations}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.NAU_CLUB}
              />
              <PrivateRoute
                component={WalletNau}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.WALLET_NAU}
              />
              <PrivateRoute
                component={SportCardLayout}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.SPORT_CARD}
              />
              <PrivateRoute
                component={WalletNauApprovalsForm}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.WALLET_NAU_APPROVALS}
              />
              <PrivateRoute
                component={UserDataContractFormWalletNau}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.USER_DATA_CONTRACT_WALLET_NAU}
              />
              <PrivateRoute
                component={UserFileContractFormWalletNau}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.FILE_CONTRACT_WALLET_NAU}
              />
              <PrivateRoute
                component={ConfirmWalletPayout}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CONFIRM_WALLET_PAYOUT + '/:id?'}
              />
              <PrivateRoute
                component={CashOnTheWay}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.WALLET_NAU_WAY_CASH}
              />
              <PrivateRoute
                component={Payments}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.PAYMENTS}
              />
              <PrivateRoute
                component={StartLoanNau}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.LOAN_NAU}
              />
              <PrivateRoute
                component={CashbackLayout}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CASHBACK}
              />
              <PrivateRoute
                component={PhoneSubscriptionPage}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.PHONE_SUBSCRIPTION}
              />
              {new Date() < new Date('2024-12-31T23:59:59') && (
                <PrivateRoute
                  component={PhoneSubscriptionLayout}
                  exact
                  hasApprovals={userStore.hasApprovals}
                  isLogged={userStore.isLoggedIn}
                  isLoggedToInst={isLoggedInToInst}
                  path={PathRoute.PHONE_SUBSCRIPTION_PROMO}
                />
              )}
              <PrivateRoute
                component={CinemaVoucherLayout}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CINEMA_VOUCHER}
              />
              <PrivateRoute
                component={CinemaVoucherPromoDirectorLayout}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CINEMA_VOUCHER_DIRECTOR_PROMO}
              />
              <PrivateRoute
                component={CinemaVoucherLayout}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.APP_CINEMA_VOUCHER}
              />
              <PrivateRoute
                component={CinemaVoucherOrderSummary}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CINEMA_VOUCHER_ORDER_SUMMARY + '/:id'}
              />
              <PrivateRoute
                component={CinemaVoucherPromoDirectorOrderSuccess}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_ORDER_SUMMARY}
              />
              <PrivateRoute
                component={CinemaVoucherPromoNauOrderSuccess}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CINEMA_VOUCHER_PROMO_NAU_ORDER_SUMMARY}
              />
              <PrivateRoute
                component={DirectorHasCollectedCinemaVoucher}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_EXPIRED}
              />
              <PrivateRoute
                component={CinemaVoucherPromoLinkExpiredLayout}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_LINK_EXPIRED}
              />
              <PrivateRoute
                component={DirectorBadDataCinemaVoucher}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_BAD_DATA}
              />
              <PrivateRoute
                component={CinemaVoucherOrderClientData}
                exact
                hasApprovals={userStore.hasApprovals}
                isLogged={userStore.isLoggedIn}
                isLoggedToInst={isLoggedInToInst}
                path={PathRoute.CINEMA_VOUCHER_ORDER_CLIENT_DATA + '/:id'}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        )}
      />
    </SWRConfig>
  );
}

export default observer(App);
