import {
  faCheck,
  faCreditCard,
  faFile,
  faGasPump,
  faHome,
  faMoneyBill,
  faMoneyBillTransfer,
  faUser,
  faUsersGear,
} from '@fortawesome/free-solid-svg-icons';
import { PathRoute } from '../constants/pathRoute/Route';
import { RouteTypeE } from '../enums/routeTypes.enums';
import { Route } from '../types/route';
import ShieldIcon from '../components/Icons/ShieldIcon';
import NauIcon from '../components/Icons/NauIcon';
import PlaneIcon from '../components/Icons/PlaneIcon';
import HouseIcon from '../components/Icons/HouseIcon';
import CarIcon from '../components/Icons/CarIcon';
import PhoneIcon from '../components/Icons/PhoneIcon';

export const myBenefitsRoutes: Route[] = [
  {
    name: 'Zwrot składki',
    to: PathRoute.CASHBACK,
    active: true,
    imageUrl: '/assets/cashbackIcon.svg',
    hiddenOnDemo: true,
  },
  {
    name: 'Kupony do kina',
    to: PathRoute.CINEMA_VOUCHER,
    active: true,
    imageUrl: '/assets/cinemaVoucher.svg',
    hiddenOnDemo: true,
  },
  {
    name: 'Abonament komórkowy',
    to: PathRoute.PHONE_SUBSCRIPTION,
    active: true,
    iconComponent: PhoneIcon,
  },
  {
    name: 'Karta paliwowa',
    to: PathRoute.FUEL_CARDS,
    active: true,
    icon: faGasPump,
    hiddenOnDemo: true,
  },
  {
    name: 'Wypłata od ręki',
    to: PathRoute.LOAN,
    active: true,
    icon: faMoneyBill,
    hiddenOnDemo: true,
  },
  {
    name: 'Historia wniosków',
    to: PathRoute.LOAN_APPLICATION_HISTORY,
    active: true,
    icon: faMoneyBillTransfer,
    hiddenOnDemo: true,
  },
  {
    name: 'E-bony',
    to: PathRoute.DISCOUNT_CODE,
    active: true,
    imageUrl: '/assets/bony.svg',
    hiddenOnDemo: true,
  },
  {
    name: 'Portfel NAU',
    to: PathRoute.WALLET_NAU,
    active: true,
    imageUrl: '/assets/wallet.svg',
    hiddenOnDemo: true,
  },
  {
    name: 'Pożyczka NAU',
    to: PathRoute.LOAN_NAU,
    active: true,
    imageUrl: '/assets/13.svg',
  },
];

export const insuranceSubroutes: Route[] = [
  {
    name: 'OC/AC Samochód',
    to: PathRoute.CAR_INSURANCE,
    active: true,
    iconComponent: CarIcon,
    hiddenOnDemo: true,
  },
  {
    name: 'Dom/mieszkanie',
    to: PathRoute.PROPERTY_INSURANCE,
    active: true,
    iconComponent: HouseIcon,
    hiddenOnDemo: true,
  },
  {
    name: 'Podróż',
    to: PathRoute.TRIP_INSURANCE,
    active: true,
    iconComponent: PlaneIcon,
    hiddenOnDemo: true,
  },
];

export const mainDashboardRoutes: Route[] = [
  {
    name: 'Strona Główna',
    to: PathRoute.PUSTY,
    active: true,
    exact: true,
    icon: faHome,
    hiddenOnDemo: false,
  },
  {
    name: 'Moje benefity',
    to: '',
    active: true,
    exact: true,
    iconComponent: NauIcon,
    subroutes: myBenefitsRoutes,
    hiddenOnDemo: false,
  },
  {
    name: 'Ubezpieczenia',
    to: '',
    active: true,
    exact: true,
    iconComponent: ShieldIcon,
    subroutes: insuranceSubroutes,
    hiddenOnDemo: false,
  },
  {
    name: 'Płatności',
    to: PathRoute.PAYMENTS,
    active: true,
    exact: true,
    icon: faCreditCard,
  },
];

export const adminDashboardRoutes: Route[] = [
  {
    name: 'Użytkownicy',
    to: PathRoute.USERS,
    active: true,
    exact: true,
    icon: faUsersGear,
  },
  {
    name: 'Rozliczenia',
    to: PathRoute.USERS_LOANS,
    active: true,
    exact: true,
    icon: faMoneyBill,
  },
];

export const informationsRoutes: Route[] = [
  {
    name: 'Kontakt',
    to: PathRoute.CONTACT,
    active: true,
    exact: true,
    type: RouteTypeE.Link,
  },
  {
    name: 'Polityka prywatności',
    to: PathRoute.PRIVACY_POLICY,
    active: true,
    type: RouteTypeE.File,
    fileName: 'Polityka_prywatnosci.pdf',
  },
  {
    name: 'Regulamin serwisu',
    to: PathRoute.REGULATIONS,
    active: true,
    type: RouteTypeE.File,
    fileName: 'Regulamin.pdf',
  },
];

export const profileRoutes: Route[] = [
  {
    name: 'Moje dane',
    to: PathRoute.USER_PROFILE,
    active: true,
    exact: true,
    icon: faUser,
  },
  {
    name: 'Zgody',
    to: PathRoute.APPROVALS,
    active: true,
    icon: faCheck,
  },
  {
    name: 'Moje pliki',
    to: PathRoute.MY_FILES,
    active: true,
    icon: faFile,
  },
];

export default [mainDashboardRoutes, informationsRoutes];
